.socials-container{ 
    
    /*margin-top: 2%;
    margin-left: 2%;
    margin-bottom: 40px;
    float: center;*/
}

.socials-icons{ 
    
    width: 60px;
    height: 60px;
    padding-left: 2.5%;
    padding-right: 2.5%;
}