.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*.video-container {
  /*max-width:700px;
  margin:0 auto;
  padding-top:0;*/
   /* padding-top: 0%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-bottom: 0%;
}*/

.shrink-video {
    padding-left: 2.5%;
    padding-right: 2.5%;
}