@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,500;0,800;1,600&display=swap);
html {
  background-color: #121212;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*.container {
  margin-right : 0 !important;
  margin-left : 0 !important;
  padding-right : 0 !important;
  padding-left : 0 !important;
}*/

.header-font{
    font-family: 'Work Sans', sans-serif;
    letter-spacing:0.2em;
    font-size: 30px;
    font-weight: 300;
}

.title {
    font-family: 'Work Sans', sans-serif;
    letter-spacing:0.2em;
    font-size: 20px;
    font-weight: 500;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white; 
    overflow: auto;
    margin-right: 15%;
    margin-left: 15%;
}

@media screen and (max-width: 1000px) {
  .small-screen {
   margin-right: 2.5%;
   margin-left: 2.5%;
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  color: white;
}

.App-link {
  color: #09d3ac;
}

.top{ 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
} 

.top-padding{
    padding-top: 5%;
}

.bottom-padding{
    padding-top: 15%;
}

.white-text{
    color: ghostwhite;
}

.header {
    text-align: center;
}

@media all and (max-width: 1200px) { /* screen size until 1200px */
    .header {
        font-size: 2em; /* 1.5x default size */
    }
}
@media all and (max-width: 1000px) { /* screen size until 1000px */
    .header {
        font-size: 1.6em; /* 1.2x default size */
        }
    }
@media all and (max-width: 500px) { /* screen size until 500px */
    .header {
        font-size: 1.2em; /* 0.8x default size */
        }
    }

.test {
    border-style: solid;
    border-color: skyblue;
}

.test-alt {
   border-style: solid;
   border-color: orangered;
}

.border {
    border-style: solid;
   border-color: black;
}

html {
  scroll-behavior: smooth;
}


.title-top {
    margin-top: 5%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.title-margins {
    margin-top: 10%;
    margin-bottom: 5%;
}

.logo-margins {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 0%;
    margin-right: 0%; 
}

.bio {
    
    margin-left: 15%;
    margin-right: 15%; 
    text-align: center;
}

.center-element {
  display:flex; /*<-- allows the magic*/
  margin:auto;
  /*align-items:center;*/ /*<-- the magic*/
  justify-content:center; /*<-- more magic*/
    padding-top: 2.5%;
    padding-bottom: 2.5%;    
}

    .shrink-stars {
        width: 27%;
        min-width: 75px;
        margin-top: 4%;
    }

@media all and (max-width: 1200px) { /* screen size until 1200px */
    .shrink-stars {
        width: 27%;
        min-width: 75px;
        margin-top: 4%;
    }
}

@media all and (max-width: 800px) { /* screen size until 1000px */
    .shrink-stars {
        width: 27%;
        min-width: 97px;
        margin-top: 5.5%;
        }
    }

@media all and (max-width: 600px) { /* screen size until 1000px */
    .shrink-stars {
        width: 27%;
        min-width: 85px;
        margin-top: 6%;
        }
    }
@media all and (max-width: 500px) { /* screen size until 500px */
    .shrink-stars {
        width: 27%;
        min-width: 75px;
        margin-top: 7%;
        }
    }

@media all and (max-width: 450px) { /* screen size until 450px */
    .shrink-stars {
        width: 27%;
        min-width: 65px;
        margin-top: 5%;
        }
    }

.adjust-position {
   /*margin-top: 1%;*/
}

.anchor-right {
    float: right;
}
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*.video-container {
  /*max-width:700px;
  margin:0 auto;
  padding-top:0;*/
   /* padding-top: 0%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-bottom: 0%;
}*/

.shrink-video {
    padding-left: 2.5%;
    padding-right: 2.5%;
}
.project-section-padding{
    padding-top: 7.5%;
    padding-left: 0%;
    padding-right: 0%;
}

.project-image{
    width: 100%;
}

.project-element-spacing {
    padding-top: 0%;
    padding-bottom: 10%;
    padding-right: 15px;
    padding-left: 15px;
}

.center-element {
  display:flex; 
  margin:auto;
  align-items:center; 
  justify-content:center; 
}

.flex-container{
    display:flex;
    flex-direction:column;
}

.text-block{
    padding-top: 2.5%;
    text-align: center;
}

a:link {
    color: black;
    text-decoration: underline;
}

a:visited {
    color: black;
    text-decoration: underline;
}

.link:hover {
    color: lightgray;
    text-decoration: underline;
}


a:active {
    color: black;
    text-decoration: underline;
}
.bandcamp-player {
    
    /*border: 0;*/
    margin-left: 2.5%;
    margin-right: 0%;
    min-width: 100px;
    width: 450px;
    height: 120px;
    /*min-width: 700px;*/
    /*height: 120px;*/
    
}

.music-section-padding{
    padding-top: 3%;
    padding-left: 0%;
    padding-right: 0%;
}

.music-module-padding{
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
}

.center-element {
  display:flex; 
  margin:auto;
  align-items:center; 
  justify-content:center; 
}

.flex-container{
    display:flex;
    flex-direction:column;
}

.bandcamp-player-height {
    min-height: 470px;
}
.portrait-image {
    width: 75%;
}

.center-element {
  display:flex; /*<-- allows the magic*/
  margin:auto;
  /*align-items:center;*/ /*<-- the magic*/
  justify-content:center; /*<-- more magic*/
    padding-top: 2.5%;
    padding-bottom: 2.5%;    
}

.bio-padding {
    padding-top: 7.5%;
    padding-bottom: 15%;
}

.socials-padding {
    margin-top: 10%;
}

.bio-text {
    text-align: center;
}

.full-width {
    width: 100%;
}

.socials {
   width: 10%;
}

.adjust-margin {
    /* didn't fix the problem of text overflowing off screen on iOS as I'd hoped*/
    margin-right: 2.5%;
    margin-left: 2.5%;
}

.grayscale-img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.socials-container{ 
    
    /*margin-top: 2%;
    margin-left: 2%;
    margin-bottom: 40px;
    float: center;*/
}

.socials-icons{ 
    
    width: 60px;
    height: 60px;
    padding-left: 2.5%;
    padding-right: 2.5%;
}
.menu-padding{
    padding-top: 1.5%;
}

.item-padding{
    padding-left: 5%;
    padding-right: 5%;
}

/*a:link {
    color: ghostwhite;
    text-decoration: underline;
}

a:visited {
    color: ghostwhite;
    text-decoration: underline;
}

.link:hover {
    color: skyblue;
    text-decoration: underline;
}


a:active {
    color: ghostwhite;
    text-decoration: underline;
}*/
