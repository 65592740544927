.title-top {
    margin-top: 5%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.title-margins {
    margin-top: 10%;
    margin-bottom: 5%;
}

.logo-margins {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 0%;
    margin-right: 0%; 
}

.bio {
    
    margin-left: 15%;
    margin-right: 15%; 
    text-align: center;
}

.center-element {
  display:flex; /*<-- allows the magic*/
  margin:auto;
  /*align-items:center;*/ /*<-- the magic*/
  justify-content:center; /*<-- more magic*/
    padding-top: 2.5%;
    padding-bottom: 2.5%;    
}

    .shrink-stars {
        width: 27%;
        min-width: 75px;
        margin-top: 4%;
    }

@media all and (max-width: 1200px) { /* screen size until 1200px */
    .shrink-stars {
        width: 27%;
        min-width: 75px;
        margin-top: 4%;
    }
}

@media all and (max-width: 800px) { /* screen size until 1000px */
    .shrink-stars {
        width: 27%;
        min-width: 97px;
        margin-top: 5.5%;
        }
    }

@media all and (max-width: 600px) { /* screen size until 1000px */
    .shrink-stars {
        width: 27%;
        min-width: 85px;
        margin-top: 6%;
        }
    }
@media all and (max-width: 500px) { /* screen size until 500px */
    .shrink-stars {
        width: 27%;
        min-width: 75px;
        margin-top: 7%;
        }
    }

@media all and (max-width: 450px) { /* screen size until 450px */
    .shrink-stars {
        width: 27%;
        min-width: 65px;
        margin-top: 5%;
        }
    }

.adjust-position {
   /*margin-top: 1%;*/
}

.anchor-right {
    float: right;
}