.project-section-padding{
    padding-top: 7.5%;
    padding-left: 0%;
    padding-right: 0%;
}

.project-image{
    width: 100%;
}

.project-element-spacing {
    padding-top: 0%;
    padding-bottom: 10%;
    padding-right: 15px;
    padding-left: 15px;
}

.center-element {
  display:flex; 
  margin:auto;
  align-items:center; 
  justify-content:center; 
}

.flex-container{
    display:flex;
    flex-direction:column;
}

.text-block{
    padding-top: 2.5%;
    text-align: center;
}

a:link {
    color: black;
    text-decoration: underline;
}

a:visited {
    color: black;
    text-decoration: underline;
}

.link:hover {
    color: lightgray;
    text-decoration: underline;
}


a:active {
    color: black;
    text-decoration: underline;
}